@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500&display=swap");
body {
  background-color: #011226 !important;
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}
a {
  color: rgb(0, 255, 217) !important;
  text-decoration: none;
  font-weight: 500 !important;
  font-family: Poppins !important;
}
h2 {
  font-size: 36px;
  font-weight: 500;
  margin: 10px auto;
}
h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 10px auto;
}
h5 {
  font-size: 16px;
  margin: 10px auto;
}

.top-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10;
}

.how-btn {
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
}
.temp {
  bottom: -500px;
  position: fixed;
  width: 100%;
  z-index: 0;
}
.transparent-div {
  background: rgba(103, 58, 58, 0.15);
  border-radius: 20px;
  border-radius: 12px;
  padding: 20px 60px;
  min-height: 200px;
  margin: 15px auto;
  position: relative;
}
.padded-container {
  padding: 0px 180px;
}
.time-text {
  text-align: center;
}
.time-text-bold {
  font-family: "Gothic A1", sans-serif;
  text-transform: uppercase;
  line-height: 2;
  text-align: center;
}
.rate {
  font-size: 20px;
}
.navigation-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background-color: rgb(1, 18, 38);
  width: 100%;
  position: fixed;
  z-index: 100;
}

.logo-image {
  height: 30px;
  padding: 0px 20px;
}
.connect-wal-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}
.connect-wal {
  background: #000;
  border: 1px solid #cba85d;
  border-radius: 90px;
  color: #20e1b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 5px 40px;
  text-transform: uppercase;
}
.time-number-element {
  width: 80px;
  height: 80px;
  background: linear-gradient(180deg, #db4a80, #d93fae);
  color: #fff;
  margin: auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid rgba(136, 161, 191, 0.5);
  border-radius: 50%;
  font-size: 35px;
  font-weight: 600;
}
.timer-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer-col-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buy-content-div {
  padding: 22px;
  border: 1.5px solid rgb(90, 81, 150);
  border-radius: 20px;
}
.buy-prompt-text {
  font-size: 20px;
}
.from-text-div,
.to-text-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.from-inputs-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px;
  height: 60px;
}
.from-inputs-div input {
  max-width: 130px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
}
.bundle-div-text {
  margin-bottom: 0px;
  padding-left: 2px;
}
.to-bundle-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.symbol-col {
  display: flex;
  justify-content: center;
}
.buy-btn-div {
  display: flex;
  justify-content: center;
}
.buy-btn {
  background: linear-gradient(90deg, #2affbf -3.88%, #36705c);
  color: #ffffff;
  box-shadow: 0 1px 8px hsla(0, 0%, 100%, 0.8);
  border-radius: 40px;
  padding: 12px 25px;
  font-weight: 600;
  margin: 30px auto;
}
.arrow-div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.arrow {
  font-size: 30px;
  font-weight: 800;
  background-color: rgb(139, 134, 164);
  padding: 10px;
  border-radius: 50%;
}

.add-fizi-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
#fiziunitstoget {
  max-width: 200px;
}
.metamask-icon {
  width: 30px;
  height: 30px;
}
.add-to-metamask-text {
  margin: 0px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: #2e2568;
  padding: 30px;
  border-radius: 10px;
  max-width: 90%; /* Allow more room for content */
  min-width: 300px; /* Ensure it doesn't get too small */
  width: auto; /* Allow width to adjust based on content */
  overflow: hidden;
  max-width: 550px;
  border: 1px solid rgb(204, 204, 204);
}
.modal-content h2 {
  padding-bottom: 20px;
}
.loading-div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
