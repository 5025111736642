@media screen and (max-width: 767px) {
  .padded-container {
    padding: 0px 10px;
  }
  .transparent-div {
    padding: 10px;
  }
  .time-number-element {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
  .arrow-div {
    align-items: center;
    margin-top: 30px;
  }
  .arrow {
    padding: 10px 11px;
  }
  .connect-wal {
    padding: 10px;
    font-size: 12px;
  }
  .smart-contract-link {
    font-size: 13px;
  }
  .fa-arrow-right {
    transform: rotate(
      90deg
    ); /* Rotates the icon 90 degrees to point downwards */
    display: inline-block; /* Ensures the transform applies correctly */
  }
  .modal-content {
    max-width: 360px;
  }
  #fiziunitstoget {
    max-width: 160px;
  }
}
